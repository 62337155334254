import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@uirack/components/ui/accordion';

import { HELP_DESCRIPTION } from '../../../common/constants';
import React from 'react';
import TooltipDes from '../../../common/Tooltip/TooltipDes';

// Accordion.js

const AccordionComponent = ({ id, triggerText, children }) => {
  const tooltipContent =
    id === 'ShowingAnalysisFor'
      ? 'Showing analysis'
      : HELP_DESCRIPTION[triggerText] || triggerText;

  return (
    <div
      id={id}
      className="rounded-md shadow border mx-auto mt-4"
      style={{
        borderColor: 'hsl(0, 0%, 80%)',
        backgroundColor: '#fdfdfd',
      }}
    >
      <Accordion
        type="multiple"
        defaultValue={[
          'Company-1',
          'Filters-1',
          'SummaryStats',
          'LinkedinStats',
        ]}
        collapsible={false}
      >
        <AccordionItem value={id}>
          <AccordionTrigger className="px-4 py-2 text-md no-underline hover:no-underline">
            <div className="flex justify-start items-center">
              {triggerText}

              <div class="px-2 h-9  flex justify-start items-center">
                <TooltipDes searchText={tooltipContent} align="start">
                  <div class="cursor-pointer relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="20px"
                      viewBox="0 -960 960 960"
                      width="20px"
                      fill="#388E3c"
                    >
                      <path d="M480-240q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm-36-153h73q0-37 6.5-52.5T555-485q35-34 48.5-58t13.5-53q0-55-37.5-89.5T484-720q-51 0-88.5 27T343-620l65 27q9-28 28.5-43.5T482-652q28 0 46 16t18 42q0 23-15.5 41T496-518q-35 32-43.5 52.5T444-393Zm36 297q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30-149.5t82.5-122Q261-804 331-834t149-30q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Zm0-72q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z" />
                    </svg>
                  </div>
                </TooltipDes>
                {/* <div class="tooltip-wrapper">
                  <div class="tooltip-wrapper__content tooltip-wrapper--align-start">
                    <div class="tooltip-wrapper__content--arrow"></div>
                    <pre class="tooltip-wrapper__text font-normal text-sm text-left">
                      {HELP_DESCRIPTION[triggerText] || triggerText}
                    </pre>
                  </div>
                  <div class="cursor-pointer relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="20px"
                      viewBox="0 -960 960 960"
                      width="20px"
                      fill="#388E3c"
                    >
                      <path d="M480-240q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm-36-153h73q0-37 6.5-52.5T555-485q35-34 48.5-58t13.5-53q0-55-37.5-89.5T484-720q-51 0-88.5 27T343-620l65 27q9-28 28.5-43.5T482-652q28 0 46 16t18 42q0 23-15.5 41T496-518q-35 32-43.5 52.5T444-393Zm36 297q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30-149.5t82.5-122Q261-804 331-834t149-30q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Zm0-72q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z" />
                    </svg>
                  </div>
                </div> */}
              </div>
            </div>
          </AccordionTrigger>
          <AccordionContent
            className="border-t p-4"
            style={{
              borderColor: 'hsl(0, 0%, 80%)',
              backgroundColor: '#f2f7fa',
              borderStyle: 'solid', // Ensure the border style is solid
            }}
          >
            {children}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default AccordionComponent;
