import React, { useState } from 'react';

import BooleanQueryComponent from './BooleanQueryComponent';
import BooleanSearchNew from './BooleanSearchNew';

const BooleanSearch = ({
  setWordsToBeExcludedInSearchObject,
  setWordsToBeIncludedInSearchObject,
}) => {
  const [isItemsVisibleIncluded, setItemsVisibleIncluded] = useState(true);
  const [isItemsVisibleExcluded, setItemsVisibleExcluded] = useState(true);

  const [inputValueIncluded, setInputValueIncluded] = useState('');
  const [inputValueExcluded, setInputValueExcluded] = useState('');

  const toggleItemsVisibilityIncluded = () => {
    if (inputValueIncluded === '') {
      setItemsVisibleIncluded(!isItemsVisibleIncluded);
    }
  };

  const toggleItemsVisibilityExcluded = () => {
    if (inputValueExcluded === '') {
      setItemsVisibleExcluded(!isItemsVisibleExcluded);
    }
  };

  const handleInputChangeIncluded = (event) => {
    setWordsToBeIncludedInSearchObject(event.target.value);
    setInputValueIncluded(event.target.value);
    setItemsVisibleIncluded(false);
  };

  const handleInputChangeExcluded = (event) => {
    setWordsToBeExcludedInSearchObject(event.target.value);
    setInputValueExcluded(event.target.value);
    setItemsVisibleExcluded(false);
  };
  const [activeTab, setActiveTab] = useState('included');

  const handleInputChangeIncludedCustom = (value) => {
    setWordsToBeIncludedInSearchObject(value);
    setInputValueIncluded(value);
    setItemsVisibleIncluded(false);
  };

  return (
    <section className=" ">
      {/* <div className="tabs">
        <button
          className={`p-4 flex-grow border-b-3 font-bold text-center cursor-pointer ${
            activeTab === 'included'
              ? 'border-primary border-b-3 text-onprimarycontainer bg-primarycontainer font-bold'
              : ''
          }`}
          onClick={() => setActiveTab('included')}
        >
          Pre-Defined
        </button>
        <button
          className={`p-4 flex-grow border-b-3 font-bold text-center cursor-pointer ${
            activeTab === 'excluded'
              ? 'border-primary border-b-3 text-onprimarycontainer bg-primarycontainer font-bold'
              : ''
          }`}
          onClick={() => setActiveTab('excluded')}
        >
          Custom
        </button>
      </div>

      {activeTab == 'included' && (
        <div className="p-4 space-y-4">
          <div className="w-full flex flex-col md:flex-row items-start">
            <div className="keyword mr-6">
              <div className="title">Included Keywords</div>
            </div>

            <div
              // className="input-label flex items-center space-x-2"
              onClick={toggleItemsVisibilityIncluded}
              className={`w-full rounded-md relative flex items-center min-w-[300px] mb-4 bg-background shadow-lg`}
              style={{ maxWidth: '672px' }}
            >
              <input
                //   value={inputValueIncluded}
                onChange={handleInputChangeIncluded}
                type="text"
                name="searchbox"
                className={`rounded-md border-transparent w-full py-3 px-4 appearance-none border border-outline bg-background
                       text-onprimarycontainer placeholder-outline text-base focus:ring-primary`}
                placeholder="Start typing in any topic/company name"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="w-full flex flex-col md:flex-row items-start">
            <div className="keyword mr-6">
              <div className="title">Excluded keywords</div>
            </div>

            <div
              className={`w-full rounded-md relative flex items-center min-w-[300px] mb-4 bg-background shadow-lg`}
              onClick={toggleItemsVisibilityExcluded}
              style={{ maxWidth: '672px' }}
            >
              <input
                className={`rounded-md border-transparent w-full py-3 px-4 appearance-none border border-outline bg-background
                      text-onprimarycontainer placeholder-outline text-base focus:ring-primary`}
                //   value={inputValueExcluded}
                placeholder="Start typing in any topic/company name"
                onChange={handleInputChangeExcluded}
                autoComplete="off"
                type="text"
                name="searchbox"
              />
            </div>
          </div>
        </div>
      )}
      {activeTab == 'excluded' && (
        // <BooleanSearchNew
        //   handleInputChangeIncludedCustom={handleInputChangeIncludedCustom}
        // />

        <BooleanQueryComponent />
      )} */}

      <BooleanQueryComponent
        handleInputChangeIncludedCustom={handleInputChangeIncludedCustom}
      />
    </section>
  );
};

export default BooleanSearch;
