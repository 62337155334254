// Icon from react-icons

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@uirack/components/ui/accordion';
import {
  AiFillDelete,
  AiOutlineClose,
  AiOutlineFileSearch,
} from 'react-icons/ai';
import { ConvoHistory, ConvoHistoryDisplayByChart } from './utils';
import { FaBars, FaCopy, FaFileDownload, FaTimes } from 'react-icons/fa';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { PiSpeakerHighDuotone, PiSpeakerSimpleXBold } from 'react-icons/pi';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { BASE_URI } from '../services/common/endpoints';
import { BsFillMicFill } from 'react-icons/bs';
import { ChatgptContext } from './context_chatgpt/ChatgptContext';
import { CiCircleInfo } from 'react-icons/ci';
import Dialog from '../common/Dialog';
import { FaUserCircle } from 'react-icons/fa';
import { FcDisclaimer } from 'react-icons/fc';
import { GoLinkExternal } from 'react-icons/go';
import { LANGUAGES_LIST } from '../common/metadata';
import { LoaderBoxes } from '../modules/App/LayoutComponents/Loader/Loader';
import { MicListingComponent } from './context_chatgpt/MicListingComponent';
import Pill from '../modules/Components/Pill';
import Select from 'react-select';
import Tooltip from '../common/Tooltip/Tooltip';
import { UserProfileContext } from '../store/context/profile.context';
import dayjs from 'dayjs';
import { getToken } from '../services/common/authToken';
import { jsPDF } from 'jspdf';
import pillsData from './prompt.json';
import { useNavigate } from 'react-router-dom';

/* eslint-disable no-undef */

const systemMessage = {
  role: 'assistant',
  content:
    'Summarize the provided data and create concise summaries or key points.',
};

let chartName = '';

const Chatgpt_API = ({ setIsFullwidth = () => {} }) => {
  const {
    setSidebarStatus,
    sidebarStatus,
    ChatgptData,
    convoId,
    setconvoId,
    PillsName,
    socialMediaType,
    mainSearchTerm,
    subtype,
    SearchObject,
    isFullWidth,
  } = useContext(ChatgptContext);
  const [lang, setlang] = useState('');
  const [toggleMic, settoggleMic] = useState(false);
  // listning with user mic
  const [isMicON, setisMicON] = useState(false);
  const [toggleScreen, settoggleScreen] = useState(false);
  const [modal, setmodal] = useState(false);
  const [promptInitialData, setPromptInitialData] = useState([]);
  const [promptApiData, setPromptApiData] = useState([]);
  const [summary, setSummary] = useState([]);
  const [prompt, setPrompt] = useState('');
  const [topPillsName, setTopPillsName] = useState('Overall Summary');
  const [isPromptData, setIsPromptData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [apiPromptDataCustom, setApiPromptData] = useState([]);
  const [isClickedAddNew, setIsClickedAddNew] = useState(false);

  const [userInputShow, setUserInputShow] = useState(false);
  const [apikeyValue, setApiKey] = useState('');
  const [selectInstruction, setSelectInstruction] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  const [newPromptName, setNewPromptName] = useState('');
  const [newPromptValue, setNewPromptValue] = useState('');
  const [updateDetails, setUpdateDetails] = useState('');
  const [isImmersiveView, setIsImmersiveView] = useState(true);
  const [historyPillsValue, setHIstoryPillsValue] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [initialData, setInitialData] = useState([]);
  const [toggleIcon, setToggleIcon] = useState(true);
  const [toggleHistory, setToggleHistory] = useState(true);
  const [toggleField, setToggleField] = useState(true);
  const [promptPillsArray, setPromptPillsArray] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState('');
  const [itemsToShow, setItemsToShow] = useState(3);

  const [showModal, setshowModal] = useState(false);
  const [showModalSubscribe, setShowModalSubscribe] = useState(false);
  const [showModalMessage, setshowModalMessage] = useState('');
  const [ModalTitle, setModalTitle] = useState('');
  const [selectedModel, setSelectedModel] = useState('mx_groq');
  const [fieldName, setFieldName] = useState([]);
  const [dateStartEnd, setDateStartEnd] = useState(0);

  const [countAPIcall, setCountAPIcall] = useState(0);

  // const profileContext = useContext(UserProfileContext);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const profileContext = useContext(UserProfileContext);
  const user = profileContext?.profileInfo?.accountname;

  const isSubscriptionActive = profileContext?.profileInfo?.subscription;
  const totalAvailableApiCount =
    profileContext?.profileInfo?.GenAiInsights?.limit;

  const totalAvailableToken =
    profileContext?.profileInfo?.GenAiInsights?.token_limit || 0;

  const [apiCount, setApiCount] = useState(0);
  const [tokenUsed, setTokenUsed] = useState();
  const [timer, setTimer] = useState(30);

  const useMouseDelta = () => {
    const [dragging, setDragging] = useState(false);
    const viewportWidth =
      window.innerWidth || document.documentElement.clientWidth;

    let initialWidth = viewportWidth * 1;
    const [result, setResult] = useState(initialWidth);
    const [currentWidth, setCurrentWidth] = useState(initialWidth);

    const origin = useRef(initialWidth);

    const setDefault = (value) => {
      setResult(value || initialWidth);
      setCurrentWidth(value || initialWidth);
      origin.current = value || initialWidth;
    };

    const handleMouseDown = useCallback((e) => {
      const elementId = (e.target || e.srcElement).id;
      origin.current = e.clientX;
      if (elementId === 'sliderChatgpt') {
        e.preventDefault();
        setDragging(true);
      }
    }, []);

    const handleMouseUp = useCallback(
      (e) => {
        setDragging(false);
        setCurrentWidth(result);
      },
      [result],
    );

    const handleMouseMove = useCallback(
      (e) => {
        if (!dragging) {
          return;
        }
        setResult(() => currentWidth - e.clientX + origin.current);
      },
      [currentWidth, dragging],
    );

    useEffect(() => {
      window.addEventListener('mousedown', handleMouseDown);
      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('click', handleMouseUp);

      return () => {
        window.removeEventListener('mousedown', handleMouseDown);
        window.removeEventListener('mouseup', handleMouseUp);
        window.removeEventListener('mousemove', handleMouseMove);
      };
    }, [dragging, handleMouseDown, handleMouseUp, handleMouseMove]);

    return { result, setDefault };
  };

  const { result, setDefault } = useMouseDelta();

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (typeof setIsFullwidth === 'function') {
      const width = window.innerWidth || document.documentElement.clientWidth;
      if (result < width && sidebarStatus) {
        setIsFullwidth(false);
      } else if (sidebarStatus) {
        setIsFullwidth(true);
      } else {
        setIsFullwidth(false);
      }
    }
  }, [result, sidebarStatus]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // toggle speaker
  const [isListing, setisListing] = useState(false);
  // text-to-speak

  const navigate = useNavigate();
  const label =
    mainSearchTerm +
      ': ' +
      socialMediaType +
      ' - ' +
      ChatgptData?.title +
      ' - ' +
      SearchObject?.days || 10 + ' days' + ' - ' + topPillsName;

  useEffect(() => {
    setPrompt(process.env.REACT_APP_CHATGPT_SUMMARIZE_PROMPT);
    return () => {
      setconvoId('');
    };
  }, []);

  useEffect(() => {
    setSummary([]);
    setTopPillsName('Overall Summary');
  }, [socialMediaType, ChatgptData, sidebarStatus]);

  const sourceData = {
    mx_groq: 'gr',
    mx_groq_llama: 'grl',
    AdvancedAI: 'ad',
    'mixt-56b': 'm56',
    internlm: 'in',
    qwen: 'qw',
    'mixt-large': 'ml',
    Claude: 'cl',
    llama3_gradient: 'lm',
    llama3_gradient_new: 'lmg',
    'openai-azure': 'ag',
  };

  const GetAndSetResponse = async () => {
    setIsLoading(true);
    // ;
    try {
      // const data = await UserHistory();

      const RequestBody = {
        social_media_pills_name: PillsName || ChatgptData?.title,
        social_media_name: socialMediaType,
        handler_name: mainSearchTerm.toLowerCase(),
        social_media_subtype: subtype,
        source: sourceData?.[selectedModel],
      };

      if (mainSearchTerm.toLowerCase()) {
        const data = await ConvoHistoryDisplayByChart(RequestBody);
        // ;

        if (data?.data?.length) {
          setInitialData(data);
          // debugger;
          // ;
          let arr = [];
          let pillsHistory = [];
          const dateArray = [];
          data?.data?.forEach((item) => {
            const obj = {
              chatId: item.chatId,
              conversationId: item.conversationId,
              date: item.date,
              chartName: item.chartName,
            };
            pillsHistory.push(obj);

            if (!dateArray.includes(item.date.split('T')[0])) {
              dateArray.push(item.date.split('T')[0]);
            }

            let currentUserMessage = null;
            // item.messages.map((message) => {
            //   if (message.role == "user") {
            //     currentUserMessage = { user: message.content, content: null };
            //   } else if (message.role == "assistant" && currentUserMessage) {
            //     currentUserMessage.content = message.content;
            //     arr.push(currentUserMessage);
            //     currentUserMessage = null;
            //   }
            // });
          });

          setSummary(arr);
          setHIstoryPillsValue(dateArray);
          // historyPills()
          // setresponse(arr); // sets response
          // ShowChat(arr[0]); //displays index 0 data from response.

          setIsLoading(false); // after getting response makes loader false.
          document.getElementById('scrollIntoView').scrollIntoView(); // scrolls chat page to the bottom so that user can see latest chat.
        } else {
          setSummary([]);
          setHIstoryPillsValue([]);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const handleSummarizeBtn = () => {
    setPrompt(`${process.env.REACT_APP_CHATGPT_SUMMARIZE_PROMPT}`);
    setSidebarStatus(false);
    setconvoId('');
    chartName = '';
  };

  const fetchData = async () => {
    const data = {
      messages: [
        { role: 'system', content: 'You are a helpful assistant.' },
        { role: 'user', content: 'What is good about Wuhan?' },
      ],
      // max_tokens: 500,
      // temperature: 0.9,
      // stream: true,
    };

    const url =
      'https://Mistral-large-haix-v1-serverless.francecentral.inference.ai.azure.com/v1/chat/completions';
    const apiKey = 'oJZ82DuAQljIjLTsXXeROfEanDKRKNT7';

    if (!apiKey) {
      throw new Error('API Key is missing');
    }

    const headers = {
      'Content-Type': 'application/json',
      'api-key': apiKey,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  async function feedToChatGPT(theJsonData) {
    // scrollToBottom();
    scrollToTop();
    const apiRequestBody = {
      model: 'gpt-3.5-turbo',
      messages: [
        systemMessage,
        {
          role: 'user',
          content: `${
            lang
              ? ` write the output in ${lang} script language only (do not use english unless specified ) for this prompt : ${prompt}`
              : prompt
          } \n. ${JSON.stringify(filteredData)}`,
        },
      ],
    };

    const newApiData = {
      prompt: `${
        lang
          ? `write the output in ${lang} script language only (do not use English unless specified) for this prompt: ${prompt}`
          : prompt
      }`,
      content: `${JSON.stringify(filteredData)}`,
      // content: theJsonData,
    };
    const newApiDataNewModel = {
      query: `${
        lang
          ? `write the output in ${lang} script language only (do not use English unless specified) for this prompt: ${prompt}`
          : prompt
      }`,
      contents: `${JSON.stringify(filteredData)}`,
      // content: theJsonData,
    };

    if (!chartName) {
      chartName = prompt;
    }

    if (selectedModel == 'AdvancedAI') {
      try {
        // https://api.openai.com/v1/chat/completions
        await fetch('https://haixgenai.com/generate/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: `${JSON.stringify(newApiData)}`,
        })
          .then((data) => {
            return data.json();
          })
          .then((data) => {
            if (data?.choices) {
              setSummary((prev) => [
                {
                  user: prompt,
                  content: data?.choices[0]?.message?.content,
                  token: data?.usage?.total_tokens,
                },
              ]);
            } else {
              setModalTitle('Error : 403');
              setshowModalMessage(`Please refresh and Login if needed`);
              setshowModal(true);
              // setTimeout(() => {
              //   setshowModal(false);
              // }, 10000);
            }

            const RequestBody = {
              _id: convoId,
              chatId: data?.id,
              chatName: chartName,
              sourceAPI: JSON.stringify(SearchObject),
              socialMediaSubType: subtype,
              conversationId: convoId,
              promptName: topPillsName,
              createdTime: data?.created,
              socialMediaType: socialMediaType,
              handlerUsed: mainSearchTerm.toLowerCase(),
              interval: SearchObject?.interval,
              socialMediaPillsName: PillsName || ChatgptData?.title,
              messages: [
                { role: 'user', content: prompt },
                { role: 'assistant', content: data.choices[0].message.content },
              ],
              usage: data?.usage,
              source: 'ad',
            };

            ConvoHistory(RequestBody)
              .then((res) => {})
              .catch((error) => {});

            setIsLoading(false);
          });
      } catch (error) {
        setIsLoading(false);
      }
    }

    if (selectedModel == 'mx_groq' && (timer == 30 || timer <= 1)) {
      try {
        // https://api.openai.com/v1/chat/completions
        const response = await fetch(`${BASE_URI}/api/groq_mistral_model/v5`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(newApiData),
        });

        // Check if response is successful (status code 2xx)
        if (response.ok) {
          setIsLoading(false);

          const data = await response.json();
          if (Number(data?.usage?.total_tokens) >= 25000) {
            const countdown = setInterval(() => {
              let timeLimit = 30;
              setTimer((timeLimit) => {
                if (timeLimit <= 1) {
                  // Time's up
                  clearInterval(countdown);
                  return 0;
                } else {
                  // Decrease timer
                  return timeLimit - 1;
                }
              });
            }, 1000);
            setTimer(30);
          }

          if (data?.choices) {
            setSummary((prev) => [
              {
                user: prompt,
                content: data?.choices[0]?.message?.content,
                token: data?.usage?.total_tokens,
              },
            ]);

            const RequestBody = {
              _id: convoId,
              chatId: data?.id,
              chatName: chartName,
              sourceAPI: JSON.stringify(SearchObject),
              socialMediaSubType: subtype,
              conversationId: convoId,
              promptName: topPillsName,
              createdTime: data?.created,
              socialMediaType: socialMediaType,
              handlerUsed: mainSearchTerm.toLowerCase(),
              interval: SearchObject?.interval,
              socialMediaPillsName: PillsName || ChatgptData?.title,
              messages: [
                { role: 'user', content: prompt },
                {
                  role: 'assistant',
                  content: data.choices[0].message.content,
                },
              ],
              usage: data?.usage,
              source: 'gr',
            };

            ConvoHistory(RequestBody)
              .then((res) => {
                // GetAndSetResponse();
              })
              .catch((error) => {});

            setIsLoading(false);
          } else if (data?.message) {
            setModalTitle('Error : 403');
            setshowModalMessage(
              'Token Limit Exceeded, Please try again after a minute',
            );
            setshowModal(true);
          } else {
            setModalTitle('Error : 500');
            setshowModalMessage(
              `Token Limit Exceeded, Please try again after a minute`,
            );
            setshowModal(true);
          }
        } else {
          setIsLoading(false);
          // Handle non-successful response (status code other than 2xx)
          setModalTitle(`Error: ${response.status}`);
          setshowModalMessage(
            `Token Limit Exceeded, Please try again after a minute`,
          );
          setshowModal(true);
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error('Error:', error);
        setModalTitle('Error');
        setshowModalMessage(
          'Token Limit Exceeded, Please try again after a minute',
        );
        setshowModal(true);
        setIsLoading(false);
      }
      setIsLoading(false);
    }

    if (selectedModel == 'mx_groq_llama') {
      try {
        // https://api.openai.com/v1/chat/completions
        const response = await fetch(`${BASE_URI}/api/groq_llama3_model/v5`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(newApiData),
        });

        // Check if response is successful (status code 2xx)
        if (response.ok) {
          setIsLoading(false);
          const data = await response.json();
          if (data?.choices) {
            setSummary((prev) => [
              {
                user: prompt,
                content: data?.choices[0]?.message?.content,
                token: data?.usage?.total_tokens,
              },
            ]);

            const RequestBody = {
              _id: convoId,
              chatId: data?.id,
              chatName: chartName,
              sourceAPI: JSON.stringify(SearchObject),
              socialMediaSubType: subtype,
              conversationId: convoId,
              promptName: topPillsName,
              createdTime: data?.created,
              socialMediaType: socialMediaType,
              handlerUsed: mainSearchTerm.toLowerCase(),
              interval: SearchObject?.interval,
              socialMediaPillsName: PillsName || ChatgptData?.title,
              messages: [
                { role: 'user', content: prompt },
                {
                  role: 'assistant',
                  content: data.choices[0].message.content,
                },
              ],
              usage: data?.usage,
              source: 'grl',
            };

            ConvoHistory(RequestBody)
              .then((res) => {
                // GetAndSetResponse();
              })
              .catch((error) => {});

            setIsLoading(false);
          } else if (data?.message) {
            setModalTitle('Error : 403');
            setshowModalMessage(data?.message);
            setshowModal(true);
          } else {
            setModalTitle('Error : 500');
            setshowModalMessage(`Please refresh and Login if needed`);
            setshowModal(true);
          }
        } else {
          setIsLoading(false);
          // Handle non-successful response (status code other than 2xx)
          setModalTitle(`Error: ${response.status}`);
          setshowModalMessage(
            `Request failed with status ${response.message || response.status}
            `,
          );
          setshowModal(true);
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error('Error:', error);
        setModalTitle('Error');
        setshowModalMessage('An error occurred while processing your request.');
        setshowModal(true);
        setIsLoading(false);
      }
      setIsLoading(false);
    }

    if (selectedModel == 'mixt-56b') {
      try {
        // https://api.openai.com/v1/chat/completions
        await fetch('https://ainfinity.co/api/mixt_56b/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: `${JSON.stringify(newApiData)}`,
        })
          .then((data) => {
            return data.json();
          })
          .then((data) => {
            if (data?.choices) {
              setSummary((prev) => [
                {
                  user: prompt,
                  content: data?.choices[0]?.message?.content,
                  token: data?.usage?.total_tokens,
                },
              ]);
            } else {
              setModalTitle('Error : 403');
              setshowModalMessage(`Please refresh and Login if needed`);
              setshowModal(true);
              // setTimeout(() => {
              //   setshowModal(false);
              // }, 10000);
            }

            const RequestBody = {
              _id: convoId,
              chatId: data?.id,
              chatName: chartName,
              sourceAPI: JSON.stringify(SearchObject),
              socialMediaSubType: subtype,
              conversationId: convoId,
              promptName: topPillsName,
              createdTime: data?.created,
              socialMediaType: socialMediaType,
              handlerUsed: mainSearchTerm.toLowerCase(),
              interval: SearchObject?.interval,
              socialMediaPillsName: PillsName || ChatgptData?.title,
              messages: [
                { role: 'user', content: prompt },
                {
                  role: 'assistant',
                  content: data.choices[0].message.content,
                },
              ],
              usage: data?.usage,
              source: 'm56',
            };

            ConvoHistory(RequestBody)
              .then((res) => {})
              .catch((error) => {});

            setIsLoading(false);
          });
      } catch (error) {
        setIsLoading(false);
      }
    }

    if (selectedModel == 'internlm') {
      try {
        // https://api.openai.com/v1/chat/completions
        await fetch('https://ainfinity.co/api/internlm/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: `${JSON.stringify(newApiData)}`,
        })
          .then((data) => {
            return data.json();
          })
          .then((data) => {
            if (data?.choices) {
              setSummary((prev) => [
                {
                  user: prompt,
                  content: data?.choices[0]?.message?.content,
                  token: data?.usage?.total_tokens,
                },
              ]);
            } else {
              setModalTitle('Error : 403');
              setshowModalMessage(`Please refresh and Login if needed`);
              setshowModal(true);
              // setTimeout(() => {
              //   setshowModal(false);
              // }, 10000);
            }

            const RequestBody = {
              _id: convoId,
              chatId: data?.id,
              chatName: chartName,
              sourceAPI: JSON.stringify(SearchObject),
              socialMediaSubType: subtype,
              conversationId: convoId,
              promptName: topPillsName,
              createdTime: data?.created,
              socialMediaType: socialMediaType,
              handlerUsed: mainSearchTerm.toLowerCase(),
              interval: SearchObject?.interval,
              socialMediaPillsName: PillsName || ChatgptData?.title,
              messages: [
                { role: 'user', content: prompt },
                {
                  role: 'assistant',
                  content: data.choices[0].message.content,
                },
              ],
              usage: data?.usage,
              source: 'in',
            };

            ConvoHistory(RequestBody)
              .then((res) => {})
              .catch((error) => {});

            setIsLoading(false);
          });
      } catch (error) {
        setIsLoading(false);
      }
    }

    if (selectedModel == 'qwen') {
      try {
        // https://api.openai.com/v1/chat/completions
        await fetch('https://ainfinity.co/api/qwen/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: `${JSON.stringify(newApiData)}`,
        })
          .then((data) => {
            return data.json();
          })
          .then((data) => {
            if (data?.choices) {
              setSummary((prev) => [
                {
                  user: prompt,
                  content: data?.choices[0]?.message?.content,
                  token: data?.usage?.total_tokens,
                },
              ]);
            } else {
              setModalTitle('Error : 403');
              setshowModalMessage(`Please refresh and Login if needed`);
              setshowModal(true);
              // setTimeout(() => {
              //   setshowModal(false);
              // }, 10000);
            }

            const RequestBody = {
              _id: convoId,
              chatId: data?.id,
              chatName: chartName,
              sourceAPI: JSON.stringify(SearchObject),
              socialMediaSubType: subtype,
              conversationId: convoId,
              promptName: topPillsName,
              createdTime: data?.created,
              socialMediaType: socialMediaType,
              handlerUsed: mainSearchTerm.toLowerCase(),
              interval: SearchObject?.interval,
              socialMediaPillsName: PillsName || ChatgptData?.title,
              messages: [
                { role: 'user', content: prompt },
                {
                  role: 'assistant',
                  content: data.choices[0].message.content,
                },
              ],
              usage: data?.usage,
              source: 'qw',
            };

            ConvoHistory(RequestBody)
              .then((res) => {})
              .catch((error) => {});

            setIsLoading(false);
          });
      } catch (error) {
        setIsLoading(false);
      }
    }
    if (selectedModel == 'mixt-large') {
      const requestdata = {
        messages: [
          { role: 'system', content: `You are a helpful assistant.` },
          { role: 'user', content: prompt + JSON.stringify(theJsonData) },
        ],
      };
      try {
        // https://api.openai.com/v1/chat/completions
        await fetch(`${BASE_URI}/api/call_mistral_model/v5`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
          },

          body: JSON.stringify(requestdata),

          // body: `${JSON.stringify(newApiData)}`,

          //        const newApiData = {
          //   prompt: `${
          //     lang
          //       ? `write the output in ${lang} script language only (do not use English unless specified) for this prompt: ${prompt}`
          //       : prompt
          //   }`,
          //   content: `${JSON.stringify(theJsonData)}`,
          //   // content: theJsonData,
          // };
        })
          .then((data) => {
            return data.json();
          })
          .then((data) => {
            if (data?.choices) {
              setSummary((prev) => [
                {
                  user: prompt,
                  content: data?.choices[0]?.message?.content,
                  token: data?.usage?.total_tokens,
                },
              ]);
            } else {
              setModalTitle('Error : 403');
              setshowModalMessage(`Please refresh and Login if needed`);
              setshowModal(true);
              // setTimeout(() => {
              //   setshowModal(false);
              // }, 10000);
            }

            const RequestBody = {
              _id: convoId,
              chatId: data?.id,
              chatName: chartName,
              sourceAPI: JSON.stringify(SearchObject),
              socialMediaSubType: subtype,
              conversationId: convoId,
              promptName: topPillsName,
              createdTime: data?.created,
              socialMediaType: socialMediaType,
              handlerUsed: mainSearchTerm.toLowerCase(),
              interval: SearchObject?.interval,
              socialMediaPillsName: PillsName || ChatgptData?.title,
              messages: [
                { role: 'user', content: prompt },
                {
                  role: 'assistant',
                  content: data.choices[0].message.content,
                },
              ],
              usage: data?.usage,
              source: 'ml',
            };

            ConvoHistory(RequestBody)
              .then((res) => {})
              .catch((error) => {});

            setIsLoading(false);
          });
      } catch (error) {
        setIsLoading(false);
      }
    }

    if (selectedModel == 'Claude') {
      const requestdata = {
        input_text: prompt + JSON.stringify(theJsonData),
        input_key: apikeyValue,
        system_prompt_instructions: selectInstruction,
      };
      try {
        await fetch(`${BASE_URI}/api/claude/v5`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
          },

          body: JSON.stringify(requestdata),

          // body: `${JSON.stringify(newApiData)}`,

          //        const newApiData = {
          //   prompt: `${
          //     lang
          //       ? `write the output in ${lang} script language only (do not use English unless specified) for this prompt: ${prompt}`
          //       : prompt
          //   }`,
          //   content: `${JSON.stringify(theJsonData)}`,
          //   // content: theJsonData,
          // };
        })
          .then((data) => {
            return data.json();
          })
          .then((data) => {
            if (data?.choices) {
              setSummary((prev) => [
                {
                  user: prompt,
                  content: data?.choices[0]?.message?.content,
                  token: data?.usage?.total_tokens,
                },
              ]);
            } else {
              setModalTitle('Error : 403');
              setshowModalMessage(`Please refresh and Login if needed`);
              setshowModal(true);
              // setTimeout(() => {
              //   setshowModal(false);
              // }, 10000);
            }

            const RequestBody = {
              _id: convoId,
              chatId: data?.id,
              chatName: chartName,
              sourceAPI: JSON.stringify(SearchObject),
              socialMediaSubType: subtype,
              conversationId: convoId,
              promptName: topPillsName,
              createdTime: data?.created,
              socialMediaType: socialMediaType,
              handlerUsed: mainSearchTerm.toLowerCase(),
              interval: SearchObject?.interval,
              socialMediaPillsName: PillsName || ChatgptData?.title,
              messages: [
                { role: 'user', content: prompt },
                {
                  role: 'assistant',
                  content: data.choices[0].message.content,
                },
              ],
              usage: data?.usage,
              source: 'cl',
            };

            ConvoHistory(RequestBody)
              .then((res) => {})
              .catch((error) => {});

            setIsLoading(false);
          });
      } catch (error) {
        setIsLoading(false);
      }
    }
    if (selectedModel == 'llama3_gradient') {
      try {
        // https://api.openai.com/v1/chat/completions
        await fetch('https://haixgenai.com/api/llama3_gradient/v5?', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Authorization: `Bearer ${getToken()}`,
          },
          body: `${JSON.stringify(newApiDataNewModel)}`,
        })
          .then((data) => {
            return data.json();
          })
          .then((data) => {
            if (data?.choices) {
              setSummary((prev) => [
                {
                  user: prompt,
                  content: data?.choices[0]?.content,
                  token: data?.usage?.total_tokens,
                },
              ]);
            } else {
              setModalTitle('Error : 403');
              setshowModalMessage(`Please refresh and Login if needed`);
              setshowModal(true);
              // setTimeout(() => {
              //   setshowModal(false);
              // }, 10000);
            }

            const RequestBody = {
              _id: convoId,
              chatId: data?.id,
              chatName: chartName,
              sourceAPI: JSON.stringify(SearchObject),
              socialMediaSubType: subtype,
              conversationId: convoId,
              promptName: topPillsName,
              createdTime: data?.created,
              socialMediaType: socialMediaType,
              handlerUsed: mainSearchTerm.toLowerCase(),
              interval: SearchObject?.interval,
              socialMediaPillsName: PillsName || ChatgptData?.title,
              messages: [
                { role: 'user', content: prompt },
                { role: 'assistant', content: data.choices[0].content },
              ],
              usage: data?.usage,
              source: selectedModel == 'lm',
            };

            ConvoHistory(RequestBody)
              .then((res) => {})
              .catch((error) => {});

            setIsLoading(false);
          });
      } catch (error) {
        setIsLoading(false);
      }
    }

    if (selectedModel == 'llama3_gradient_new') {
      try {
        // https://api.openai.com/v1/chat/completions
        await fetch('https://ainfinity.co/api/llama3_gradient/v5?', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: `${JSON.stringify(newApiDataNewModel)}`,
        })
          .then((data) => {
            return data.json();
          })
          .then((data) => {
            if (data?.choices) {
              setSummary((prev) => [
                {
                  user: prompt,
                  content: data?.choices[0]?.content,
                  token: data?.usage?.total_tokens,
                },
              ]);
            } else {
              setModalTitle('Error : 403');
              setshowModalMessage(`Please refresh and Login if needed`);
              setshowModal(true);
              // setTimeout(() => {
              //   setshowModal(false);
              // }, 10000);
            }

            const RequestBody = {
              _id: convoId,
              chatId: data?.id,
              chatName: chartName,
              sourceAPI: JSON.stringify(SearchObject),
              socialMediaSubType: subtype,
              conversationId: convoId,
              promptName: topPillsName,
              createdTime: data?.created,
              socialMediaType: socialMediaType,
              handlerUsed: mainSearchTerm.toLowerCase(),
              interval: SearchObject?.interval,
              socialMediaPillsName: PillsName || ChatgptData?.title,
              messages: [
                { role: 'user', content: prompt },
                {
                  role: 'assistant',
                  content: data.choices[0].content,
                },
              ],
              usage: data?.usage,
              source: 'lmg',
            };

            ConvoHistory(RequestBody)
              .then((res) => {})
              .catch((error) => {});

            setIsLoading(false);
          });
      } catch (error) {
        setIsLoading(false);
      }
    }

    if (selectedModel == 'openai-azure') {
      try {
        // https://api.openai.com/v1/chat/completions
        const response = await fetch(
          'https://genai-002.openai.azure.com/openai/deployments/gpt-4o/chat/completions?api-version=2024-02-15-preview',
          {
            method: 'POST',
            headers: {
              // Authorization:
              //   "Bearer " + process.env.REACT_APP_CHATGPT_SUMMARIZE_API_KEY,
              'Content-Type': 'application/json',
              'api-key': '660806b0d8824f8f9cc83bc456f630d4',
              max_tokens: 800,
              temperature: 0.7,
              frequency_penalty: 0,
              presence_penalty: 0,
              top_p: 0.95,
              stop: null,
            },
            body: JSON.stringify(apiRequestBody),
          },
        );

        // Check if response is successful (status code 2xx)
        if (response.ok) {
          setIsLoading(false);
          const data = await response.json();
          if (data?.choices) {
            setSummary((prev) => [
              {
                user: prompt,
                content: data?.choices[0]?.message?.content,
                token: data?.usage?.total_tokens,
              },
            ]);
            const RequestBody = {
              _id: convoId,
              chatId: data?.id,
              chatName: chartName,
              sourceAPI: JSON.stringify(SearchObject),
              socialMediaSubType: subtype,
              conversationId: convoId,
              promptName: topPillsName,
              createdTime: data?.created,
              socialMediaType: socialMediaType,
              handlerUsed: mainSearchTerm.toLowerCase(),
              interval: SearchObject?.interval,
              socialMediaPillsName: PillsName || ChatgptData?.title,
              messages: [
                { role: 'user', content: prompt },
                { role: 'assistant', content: data.choices[0].message.content },
              ],
              usage: data?.usage,
              source: selectedModel == 'Default' ? 'ag' : 'ms',
            };

            ConvoHistory(RequestBody)
              .then((res) => {})
              .catch((error) => {});

            setIsLoading(false);
          } else if (data?.message) {
            setModalTitle('Error : 403');
            setshowModalMessage(data?.message);
            setshowModal(true);
            setIsLoading(false);
          } else {
            setModalTitle('Error : 500');
            setshowModalMessage(`Please refresh and Login if needed`);
            setshowModal(true);
            setIsLoading(false);
          }
        } else {
          // Handle non-successful response (status code other than 2xx)
          setModalTitle(`Error: ${response?.status}`);
          setshowModalMessage(
            `Request failed with status : ${
              response?.error?.message || response.status
            }`,
          );
          setshowModal(true);
          setIsLoading(false);
        }
      } catch (error) {
        // Handle network errors or other exceptions
        const errorMessage = await response.text();

        console.error('Error:', errorMessage, error);
        setModalTitle('Error');
        setshowModalMessage(
          `An error occurred while processing your request. ${response?.error?.message}`,
        );
        setshowModal(true);
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (prompt?.length > 0) {
      setIsPromptData(true);
    } else {
      setIsPromptData(false);
    }
  }, [prompt]);

  const handleFeedBtn = async () => {
    setCountAPIcall(countAPIcall + 1);

    if (ChatgptData != null) {
      const count = await handleGetApiCountByUser();
      const tokenCount = await getTokonCount();

      if (count >= totalAvailableApiCount) {
        setModalTitle('Subscription');
        setshowModalMessage(
          'You have exceeded the Co-Pilot Assistant Limit. Subscribe link for more details.',
        );
        setShowModalSubscribe(true);
        setIsLoading(false);
        // alert(
        //   'You have exceeded the Co-Pilot Assistant Limit. Subscribe link for more details.',
        // );
        setIsLoading(false);
      } else {
        setIsLoading(true);
        await feedToChatGPT(ChatgptData.excelData.slice(0, 40));
      }
    }
  };

  const [postPromptData, setPostPromptData] = useState({
    promptname: topPillsName,
    promptvalue: prompt,
    chartName: 'Test...',
  });

  useEffect(() => {
    const delay = 1000;
    const timerId = setTimeout(() => {
      // handleGetRequest();
    }, delay);
    return () => clearTimeout(timerId);
  }, []);

  useEffect(() => {
    const delay = 2000;
    const timerId = setTimeout(() => {
      getTokonCount();
    }, delay);
    return () => clearTimeout(timerId);
  }, []);

  useEffect(() => {
    const delay = 1000;
    const timerId = setTimeout(() => {
      setHIstoryPillsValue([]);
      setPromptPillsArray([]);
      setInitialData([]);
      if (sidebarStatus) {
        GetAndSetResponse();
        handleGetRequest();
      }
    }, delay);
    return () => clearTimeout(timerId);
  }, [ChatgptData, selectedModel, sidebarStatus]);

  useEffect(() => {
    if (ChatgptData?.title) {
      handleGetRequestInititalData();
    }
  }, [ChatgptData, selectedModel]);

  const handlePostRequest = async () => {
    const newPromptData = {
      promptName: newPromptName,
      promptValue: newPromptValue,
      chartName: ChatgptData ? ChatgptData.title : '',
      socialMediaName: socialMediaType,
      source: sourceData[selectedModel],
    };
    try {
      // Replace 'http://172.187.179.39:3002/api/promptnameupdate/v5' with your actual API endpoint
      const response = await fetch(
        `${BASE_URI}/api/promptnameupdate/v5?source=${sourceData[selectedModel]} `,
        {
          method: 'POST',
          headers: {
            // Authorization:
            Authorization: `Bearer ${getToken()}`,
            'Content-Type': 'application/json',
            // "api-key": process.env.REACT_APP_CHATGPT_API_KEY,
          },
          body: JSON.stringify(newPromptData),
        },
      );
      console.log(response);
      if (response.ok) {
        setModalTitle('Add Prompts');
        setshowModalMessage(`Prompt Added Successfully`);
        setshowModal(true);
        setTimeout(() => setshowModal(false), 2000);
      }
      if (!response.ok) {
        // Handle non-2xx responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      // Handle the response data as needed
      setIsClickedAddNew(false);
      setNewPromptName('');
      setNewPromptValue('');
      handleGetRequest();
    } catch (error) {
      // Handle errors
      console.error('Error in Post Request:', error.message);
    }
  };

  const handleGetRequest = async () => {
    try {
      // Replace 'https://alivesearch.live/api/promptnamehistory/v5' with your actual API endpoint
      // const queryString = new URLSearchParams(newPromptData).toString();
      const url = `${BASE_URI}/api/promptnamehistory/v5?&source=${sourceData[selectedModel]} `;

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          // Authorization:
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${getToken()}`,
          // "api-key": process.env.REACT_APP_CHATGPT_API_KEY,
        },
      });

      if (!response.ok) {
        // Handle non-2xx responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      setApiPromptData(responseData);
      setPromptInitialData([...promptInitialData, ...responseData]);
      // Handle the response data as needed
    } catch (error) {
      // Handle errors
      console.error('Error in Get Request:', error.message);
    }
  };

  // useEffect(() => {
  //   console.log(topPillsName, 'topPillsNametest');
  //   handleGetRequest();
  // }, [selectedModel]);
  const handleGetApiCountByUser = async () => {
    try {
      // Replace 'https://alivesearch.live/api/promptnamehistory/v5' with your actual API endpoint
      // const queryString = new URLSearchParams(newPromptData).toString();
      const url = `${BASE_URI}/api/stats/userHistory?task=count`;

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          // Authorization:
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${getToken()}`,
          // "api-key": process.env.REACT_APP_CHATGPT_API_KEY,
        },
      });

      if (!response.ok) {
        // Handle non-2xx responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log(responseData?.prompt_count, 'apicount');
      setApiCount(responseData?.prompt_count);

      return responseData?.prompt_count;
    } catch (error) {
      // Handle errors
      console.error('Error in Get Request:', error.message);
      return 0;
    }
  };

  const getTokonCount = async () => {
    try {
      // Replace 'https://alivesearch.live/api/promptnamehistory/v5' with your actual API endpoint
      // const queryString = new URLSearchParams(newPromptData).toString();
      const url = `${BASE_URI}/api/stats/userHistory?task=token`;

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          // Authorization:
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${getToken()}`,
          // "api-key": process.env.REACT_APP_CHATGPT_API_KEY,
        },
      });

      if (!response.ok) {
        // Handle non-2xx responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log(responseData?.total_tokens_by_user, 'apicount');
      setTokenUsed(responseData?.total_tokens_by_user);

      return responseData?.total_tokens_by_user;
    } catch (error) {
      // Handle errors
      console.error('Error in Get Request:', error.message);
      return 0;
    }
  };

  const handleGetRequestInititalData = async () => {
    try {
      // Replace 'https://alivesearch.live/api/promptnamehistory/v5' with your actual API endpoint
      // const queryString = new URLSearchParams(newPromptData).toString();
      const url = `${BASE_URI}/api/default_prompt_name_history/v5?social_media_pills_name=${ChatgptData?.title}&social_media_name=${socialMediaType}&source=${sourceData[selectedModel]} 
`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          // Authorization:
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json',
          // "api-key": process.env.REACT_APP_CHATGPT_API_KEY,
        },
      });

      if (!response.ok) {
        // Handle non-2xx responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      // setApiPromptData(responseData);
      // setPromptInitialData([...promptInitialData, ...responseData]);
      // Handle the response data as needed
      setPromptApiData(responseData);
    } catch (error) {
      // Handle errors
      console.error('Error in Get Request:', error.message);
    }
  };

  const handleEditRequest = async () => {
    console.log(updateDetails, 'updateDetailsTest');
    const updatePromptData = {
      promptName: updateDetails.label || updateDetails.promptName,
      promptValue: prompt,
      chartName: updateDetails.chartName,
      socialMediaName: updateDetails.socialMediaName,
      id: updateDetails.id,
      jobtype: 'promptedit',
    };
    const promptNames = updateDetails.label || updateDetails.promptName;
    try {
      // Replace 'http://172.187.179.39:3002/api/promptnameupdate/v5' with your actual API endpoint
      const response = await fetch(
        //  "https://alivesearch.live/api/edit/v5",
        `${BASE_URI}/api/edit/v5?jobtype=promptedit&promptName=${promptNames}&promptValue=${prompt}&chartName=${updateDetails.chartName}&socialMediaName=${updateDetails.socialMediaName}&id=${updateDetails.id}&source=${sourceData[selectedModel]} `,
        {
          method: 'POST',
          headers: {
            // Authorization:
            Authorization: `Bearer ${getToken()}`,
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.ok) {
        setModalTitle('Update Prompts');
        setshowModalMessage(`Prompt Updated Successfully`);
        setshowModal(true);
        setTimeout(() => setshowModal(false), 2000);
      }
      if (!response.ok) {
        // Handle non-2xx responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      // Handle the response data as needed
      setIsClickedAddNew(false);
      setNewPromptName('');
      setNewPromptValue('');
      setUpdateDetails('');
      handleGetRequest();
    } catch (error) {
      // Handle errors
      console.error('Error in Post Request:', error.message);
    }
  };

  const handleDeleteRequest = async () => {
    const updatePromptData = {
      jobtype: 'promptdelete',
      id: updateDetails.id,
    };
    try {
      const response = await fetch(
        `${BASE_URI}/api/remove/v5?id=${updateDetails.id}&jobtype=promptdelete`,
        {
          method: 'POST',
          headers: {
            // Authorization:
            Authorization: `Bearer ${getToken()}`,
            'Content-Type': 'application/json',
            // "api-key": process.env.REACT_APP_CHATGPT_API_KEY,
          },
          //  body: JSON.stringify(updatePromptData),
        },
      );
      if (response.ok) {
        setModalTitle('Delete Prompts');
        setshowModalMessage(`Prompt Deleted Successfully`);
        setshowModal(true);
        setTimeout(() => setshowModal(false), 2000);
      }
      if (!response.ok) {
        // Handle non-2xx responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      // Handle the response data as needed
      setIsClickedAddNew(false);
      setNewPromptName('');
      setNewPromptValue('');
      setUpdateDetails('');
      handleGetRequest();
    } catch (error) {
      // Handle errors
      console.error('Error in Post Request:', error.message);
    }
  };

  // const { listen, listening, stop } = useSpeechRecognition({
  //   onResult: (result) => {
  //     setPrompt(result);
  //   },
  // });

  function getuserMicData() {
    setisMicON(true);
    recognition.start();
    // if (!listening) {
    //   listen({ continuous: true });
    // }
  }

  console.log(
    result,
    window.innerWidth || document.documentElement.clientWidth,
    'resultt',
  );

  function StopMic() {
    setisMicON(false);
    // stop();
    recognition.stop();

    if (prompt) {
      handleFeedBtn();
    }
  }

  const [text, setText] = useState('');
  const [recognizedText, setRecognizedText] = useState('');

  // Check if the browser supports SpeechRecognition
  const SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition;

  const recognition = new SpeechRecognition();
  recognition.continuous = true;
  recognition.interimResults = false;
  recognition.lang = 'en-US';

  let speechInstance = null;

  // Handle Speech Recognition result
  recognition.onresult = (event) => {
    const transcript = event.results[event.resultIndex][0].transcript;
    setPrompt(transcript);
  };

  // function to convert and download pdf
  const convertHtmlToPdf = () => {
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'in',
    });

    doc.html(document.getElementById('pdf'), {
      callback: () => {
        doc.save('genaireport.pdf');
      },

      x: 0.1,
      y: 0,
      width: 8, //target width in the PDF document
      windowWidth: 650, //window width in CSS pixels
      autoPaging: true,
    });

    setModalTitle('Download PDF');
    setshowModalMessage(`PDF Downloaded Successfully`);
    setshowModal(true);
    setTimeout(() => setshowModal(false), 2000);
  };

  useEffect(() => {
    historyPills();
  }, [selectedDate, selectedPrompt, historyPillsValue]);

  const historyPills = () => {
    if (initialData?.data?.length) {
      // setInitialData(data);
      // debugger;
      // ;
      let arr = [];
      let promptPillsArrayTemp = [];
      let pillsHistory = [];
      initialData?.data?.forEach((item) => {
        if (selectedDate != item.date.split('T')[0]) {
          return;
        }

        const obj = {
          id: item.chatId,
          value: item.promptName,
        };

        promptPillsArrayTemp.push(obj);
        if (selectedPrompt && selectedPrompt != item.chatId) {
          return;
        }
        let currentUserMessage = null;
        item.messages.map((message) => {
          if (message.role == 'user') {
            currentUserMessage = { user: message.content, content: null };
          } else if (message.role == 'assistant' && currentUserMessage) {
            currentUserMessage.content = message.content;
            arr.push(currentUserMessage);
            currentUserMessage = null;
          }
        });
      });
      setPromptPillsArray(promptPillsArrayTemp);
      if (selectedDate && selectedPrompt) {
        setSummary(arr);
      }
      // setHIstoryPillsValue(pillsHistory);
      // setresponse(arr); // sets response
      // ShowChat(arr[0]); //displays index 0 data from response.

      setIsLoading(false); // after getting response makes loader false.
    }
  };

  const getColumn = () => {
    if (
      !ChatgptData ||
      !ChatgptData.excelData ||
      ChatgptData.excelData.length === 0
    ) {
      return (
        <div>
          No data found for the selection. Change the search (days etc) for the
          selection. Change the search (days etc).
        </div>
      );
    } else {
      const columnNames = Object.keys(ChatgptData?.excelData?.[0]);

      const options = columnNames?.map((option) => ({
        value: option,
        label: option,
      }));

      setFieldName(options);
    }
  };
  const filterColumns = () => {
    if (
      !ChatgptData ||
      !ChatgptData.excelData ||
      ChatgptData.excelData.length === 0
    ) {
      return null;
    } else {
      const filteredData = Object.values(ChatgptData?.excelData).map(
        (entry) => {
          const filteredEntry = {};
          selectedOptions.forEach((option) => {
            if (option.value in entry) {
              filteredEntry[option.value] = entry[option.value];
            }
          });
          return filteredEntry;
        },
      );
      setFilteredData(filteredData);
    }
  };

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  useEffect(() => {
    if (ChatgptData?.title == 'Viral Posts Timeline') {
      const defaultSelection = [
        { label: 'Date', value: 'Date' },
        { label: 'Username', value: 'Username' },
        { label: 'User Profile URL', value: 'User Profile URL' },
        { label: 'Engagement', value: 'Engagement' },
        { label: 'Post URL', value: 'Post URL' },
        { label: 'Sentiment Label', value: 'Sentiment Label' },
        { label: 'Post Caption', value: 'Post Caption' },
        { label: 'Comment Text', value: 'Comment Text' },
        { label: 'Comment URL', value: 'Comment URL' },
        { label: 'User Location', value: 'User Location' },

        { label: 'namedLocation', value: 'namedLocation' },
        { label: 'summarizedInsights', value: 'summarizedInsights' },
        { label: 'videoInsightsIndexer', value: 'videoInsightsIndexer' },
      ];
      setSelectedOptions(defaultSelection);
    } else {
      const arr = [];
      if (
        !ChatgptData ||
        !ChatgptData.excelData ||
        ChatgptData.excelData.length === 0
      ) {
        return [{ value: '', label: '' }];
      } else {
        const columnNames = Object.keys(ChatgptData?.excelData?.[0]);

        const options = columnNames?.map((option) => ({
          value: option,
          label: option,
        }));

        //  setFieldName(options);
        setSelectedOptions(options);
      }
    }
    getColumn();
  }, [ChatgptData]);

  useEffect(() => {
    filterColumns();
  }, [selectedOptions]);

  const CopyTextButton = () => {
    const copyToClipboard = () => {
      navigator.clipboard
        .writeText(summary?.[0]?.content)
        .then(() => {
          // alert("Text copied to clipboard!");
        })
        .catch((error) => {
          console.error('Error copying text: ', error);
        });
    };

    return (
      <button onClick={copyToClipboard} className="copy-button">
        <FaCopy />
      </button>
    );
  };

  function extractURLAndText(str) {
    const urlRegex = /(https?:\/\/\S+)/;
    const match = str.match(urlRegex);

    if (match) {
      const url = match[0];
      const text = str.replace(url, '').trim();
      return { url, text };
    } else {
      return { url: null, text: str };
    }
  }
  const [options, setOptions] = useState([]);

  useEffect(() => {
    console.log(apiPromptDataCustom, promptApiData, 'apiPromptDataCustom');
    const defaultOptions = promptApiData
      .filter((value) => value.chartName === ChatgptData?.title)
      .map((value) => ({
        value: value.promptValue,
        label: value.promptName,
        type: 'default',
        id: value.id,
        chartName: value.chartName,
        socialMediaName: value.socialMediaName,
      }));

    let customOptions = [];
    customOptions = apiPromptDataCustom
      ?.filter((value) => value.chartName == ChatgptData?.title)
      .map((value) => ({
        value: value.promptValue,
        label: value.promptName,
        type: 'custom',
        id: value.id,
        chartName: value.chartName,
        socialMediaName: value.socialMediaName,
      }));

    let defaultPrompt = [
      {
        isActive: true,
        label: 'Overall Summary',
        value:
          'Show summary in bullet points highlighting key issues , trends and dates with variation.If applicable, provide the peak positive & peak negative ',
        type: 'default',
      },
    ];

    setOptions([...defaultPrompt, ...defaultOptions, ...customOptions]);
  }, [promptApiData, ChatgptData, apiPromptDataCustom]);

  const handleChange = (selectedOption) => {
    console.log(selectedOption, 'selectedOption');
    if (selectedOption.type === 'default') {
      setTopPillsName(selectedOption.label);
      setPrompt(selectedOption.value);
      setUpdateDetails('');
      setIsClickedAddNew(false);
    } else if (selectedOption.type === 'custom') {
      // handleChangeCustomPrompt(selectedOption);
      setTopPillsName(selectedOption.label);
      setPrompt(selectedOption.value);
      setUpdateDetails(selectedOption);
      setIsClickedAddNew(false);
    }
  };

  console.log(SearchObject, 'SearchObjectTest');

  useEffect(() => {
    if (SearchObject) {
      calculateDaysDifference(SearchObject?.startDate, SearchObject?.endDate);
    }
  }, [SearchObject]);

  const calculateDaysDifference = (startDate, endDate) => {
    // Parse the dates to ensure they are valid Date objects
    const parsedStartDate = new Date(startDate);
    const parsedEndDate = new Date(endDate);

    // Calculate the time difference in milliseconds
    const timeDifference = parsedEndDate - parsedStartDate;

    // Convert milliseconds to days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    setDateStartEnd(daysDifference);
  };
  const RefreshButton = () => {
    GetAndSetResponse();
  };

  const subscribed_leads_limit = totalAvailableToken;
  const requested_leads_limit = 14;
  const processed_leads_limit = tokenUsed;

  const overlayStyle = {
    backgroundColor: 'transparent',
    backdropFilter: 'none',
    zIndex: 40, // Set the overlay background color to transparent
  };

  const Progression = (
    processed,
    subscribed,
    requested,
    title = '',
    description = '',
    lastUpdated = '',
  ) => {
    const progressPercentage = () => {
      if (!subscribed || !processed || processed <= 0 || subscribed <= 0) {
        return '0%';
      }

      let progress = (processed / subscribed) * 100;
      if (progress > 100) {
        progress = 100;
      }

      return Math.round(progress) + '%';
    };

    return (
      <div className="mr-auto w-full pb-4 md:w-[1/2] 2xl:w-[1/3] mb-4 rounded-md overflow-hidden shadow-lg bg-surface">
        <div className="px-6 py-4">
          <div className="text-center mb-2">
            <h2 className="text-lg text-left items-center flex font-semibold">
              {title}
            </h2>
            {/* <p className="text-xs text-left text-gray-500">
              Last Updated: {lastUpdated}
            </p> */}
          </div>
          <div className="flex justify-between">
            <div className="flex justify-start items-center gap-2 mb-4">
              <div className="progress-container bg-primarycontainer h-3 rounded-lg w-48">
                <div
                  className={`progress-bar ${
                    processed && subscribed
                      ? processed > subscribed
                        ? 'bg-warning'
                        : 'bg-primary'
                      : 'bg-primary'
                  }  h-full rounded-lg`}
                  style={{
                    width: progressPercentage(),
                  }}
                ></div>
                <div className="flex flex-col ">
                  <span className="text-sm text-gray-500">
                    {processed}/{subscribed} Used/Total
                  </span>
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <button
                onClick={() => {
                  setSidebarStatus(false);
                  navigate('/profile/genaiSubscription');
                }}
                className={`hover:bg-primary text-primary font-bold py-2 px-4 rounded ring-1 ${
                  processed && subscribed
                    ? processed > subscribed
                      ? 'ring-warning hover:bg-warning text-warning hover:text-onprimary'
                      : 'ring-primary hover:bg-primary hover:text-onprimary opacity-50 hover:opacity-50'
                    : 'ring-primary hover:bg-primary hover:text-onprimary opacity-50 hover:opacity-50'
                }`}
              >
                Add more
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      id="mySidenavChatgpt"
      style={{ width: isFullWidth ? '100%' : result ? result : '80%' }}
      className={`sidenav !z-[31] ${sidebarStatus ? 'block' : 'hidden'}`}
    >
      <div className="flexy">
        <div id="sliderChatgpt" className="slider fixed"></div>
        <div className="w-full h-full flex flex-col gap-2">
          <div className="w-full flex items-center justify-between px-5 py-4 border-b-[1.5px] border-gray-400">
            <p className="text-[20px] font-medium">
              {ChatgptData &&
                mainSearchTerm?.toUpperCase() +
                  ': ' +
                  (socialMediaType !== undefined ? socialMediaType : '') +
                  (subtype === 'userposts' || subtype === 'manual_users'
                    ? ' User Account '
                    : subtype === 'userhashtags' ||
                      subtype === 'manual_hashtags'
                    ? ' User Hashtag '
                    : subtype === 'manual_tags'
                    ? ' User Tag '
                    : subtype === 'keywordposts'
                    ? ' Keywords '
                    : '') +
                  (subtype ? ' - ' : '') +
                  (ChatgptData.title !== undefined
                    ? ' "' + ChatgptData.title + '" '
                    : '') +
                  ' data - "' +
                  (SearchObject.updatedDays ||
                    SearchObject.days ||
                    dateStartEnd) +
                  ' days' +
                  '"' +
                  (SearchObject.interval !== undefined
                    ? ' by ' + SearchObject.interval
                    : '')}
            </p>

            <div className="flex justify-center items-center">
              <button title="Disclaimer" onClick={() => setmodal(true)}>
                <FcDisclaimer className="text-lg" />
              </button>
              {/* <button
                title="Open Chat History in HaiX AI Assistant Page"
                alt="Default Prompt"
                className="w-[30px] h-[30px] rounded-full flex items-center justify-center mx-2"
                onClick={() => {
                  navigate('/haix-genai/summary', {
                    state: {
                      message: summary,
                      pillname: ChatgptData?.title,
                      socialMediaType,
                      handler: mainSearchTerm,
                      ChatgptData,
                      chartData: ChatgptData.excelData.slice(0, 40),
                    },
                  });
                  handleSummarizeBtn();
                }}
              >
                <GoLinkExternal className="text-[16px] text-green-600" />
              </button> */}
              <button
                title="Close"
                onClick={handleSummarizeBtn}
                className="mx-4"
              >
                <AiOutlineClose color="#ff0000" />
              </button>
            </div>
          </div>
          <div className="w-full mt-1 mb-3 flex flex-col items-center justify-start">
            {
              <div className="flex flex-col ml-auto w-[500px] ">
                <h5
                  className="flex justify-end  text-secondary items-center cursor-pointer py-1 gap-1"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  <div className="text-secondary">
                    {/* {isVisible && <FaAngleUp />}
               {!isVisible && <FaAngleDown />} */}
                  </div>
                  {!isVisible && <FaBars size={20} color="green" />}
                  {isVisible && <FaTimes size={20} color="green" />}
                </h5>
                {isVisible &&
                  Progression(
                    tokenUsed,
                    subscribed_leads_limit,
                    '',
                    'Token Used',
                    'Token Used',
                    '',
                  )}
              </div>
            }
            <div className="w-full px-5 mb-2">
              <div className="flex gap-2 items-center">
                <h2 className="font-medium text-lg mb-1">Select LLM</h2>
                <Tooltip
                  searchText="<p>Public LLM : like ChatGPT, your Q&A is with OpenAI and not private..</p>
                  <p>Secured LLMs : Hosted in haiX environment. all your Q&A is private and your queries are only visible to you. </p>"
                  align="start"
                >
                  <div className="cursor-pointer relative">
                    <CiCircleInfo className="text-[20px] font-bold text-green-700" />
                  </div>
                </Tooltip>
              </div>
              <div className="flex w-full overflow-x-auto ">
                <div className=" my-auto  "></div>

                <button
                  type="button2"
                  onClick={() => {
                    setSelectedModel('mx_groq');
                    setUserInputShow(false);
                  }}
                  className={`py-2 px-3 mt-1 mb-2 ml-2 ${
                    selectedModel == 'mx_groq'
                      ? 'bg-onsecondaryvariant text-onsecondary'
                      : 'hover:ring-primary hover:ring-1 shadow border rounded bg-onprimary'
                  } flex justify-flex-start items-center   transition ease-in duration-200 
        text-center text-base rounded hover:ring-onsecondaryvariant hover:shadow-md`}
                >
                  {/* <span className="material-icons mx-2">travel_explore</span> */}
                  FastestLLM-M
                </button>

                <button
                  type="button2"
                  onClick={() => {
                    setSelectedModel('openai-azure');
                    setUserInputShow(false);
                  }}
                  className={`py-2 px-3 mt-1 mb-2 ml-2 mr-1 ${
                    selectedModel == 'openai-azure'
                      ? 'bg-onsecondaryvariant text-onsecondary'
                      : 'hover:ring-primary hover:ring-1 shadow border rounded bg-onprimary'
                  } flex justify-flex-start items-center   transition ease-in duration-200 
        text-center text-base rounded hover:ring-onsecondaryvariant hover:shadow-md`}
                >
                  Public LLM
                </button>

                {user == 'team@haix.ai' && (
                  <>
                    <button
                      type="button2"
                      onClick={() => {
                        setSelectedModel('mx_groq_llama');
                        setUserInputShow(false);
                      }}
                      className={`py-2 px-3 mt-1 mb-2 ml-2 ${
                        selectedModel == 'mx_groq_llama'
                          ? 'bg-onsecondaryvariant text-onsecondary'
                          : 'hover:ring-primary hover:ring-1 shadow border rounded bg-onprimary'
                      } flex justify-flex-start items-center   transition ease-in duration-200 
        text-center text-base rounded hover:ring-onsecondaryvariant hover:shadow-md`}
                    >
                      {/* <span className="material-icons mx-2">travel_explore</span> */}
                      FastestLLM-groq-LongContext
                    </button>
                    <button
                      type="button2"
                      onClick={() => {
                        setSelectedModel('AdvancedAI');
                        setUserInputShow(false);
                      }}
                      className={`py-2 px-3 mt-1 mb-2 ml-2 ${
                        selectedModel == 'AdvancedAI'
                          ? 'bg-onsecondaryvariant text-onsecondary'
                          : 'hover:ring-primary hover:ring-1 shadow border rounded bg-onprimary'
                      } flex justify-flex-start items-center   transition ease-in duration-200 
        text-center text-base rounded hover:ring-onsecondaryvariant hover:shadow-md`}
                    >
                      {/* <span className="material-icons mx-2">travel_explore</span> */}
                      Secured LLMs - small
                    </button>
                    <button
                      type="button2"
                      onClick={() => setSelectedModel('mixt-56b')}
                      className={`py-2 px-3 mt-1 mb-2 ml-2 ${
                        selectedModel == 'mixt-56b'
                          ? 'bg-onsecondaryvariant text-onsecondary'
                          : 'hover:ring-primary hover:ring-1 shadow border rounded bg-onprimary'
                      } flex justify-flex-start items-center   transition ease-in duration-200 
        text-center text-base rounded hover:ring-onsecondaryvariant hover:shadow-md`}
                    >
                      {/* <span className="material-icons mx-2">travel_explore</span> */}
                      SecuredLLM-large
                    </button>

                    <button
                      type="button2"
                      onClick={() => {
                        setSelectedModel('mixt-large');
                        setUserInputShow(false);
                      }}
                      className={`py-2 px-3 mt-1 mb-2 ml-2 ${
                        selectedModel == 'mixt-large'
                          ? 'bg-onsecondaryvariant text-onsecondary'
                          : 'hover:ring-primary hover:ring-1 shadow border rounded bg-onprimary'
                      } flex justify-flex-start items-center   transition ease-in duration-200 
        text-center text-base rounded hover:ring-onsecondaryvariant hover:shadow-md`}
                    >
                      Longcontext-56B
                    </button>
                    <button
                      type="button2"
                      onClick={() => {
                        setSelectedModel('Claude');
                        setUserInputShow(true);
                      }}
                      className={`py-2 px-3 mt-1 mb-2 ml-2 ${
                        selectedModel == 'Claude'
                          ? 'bg-onsecondaryvariant text-onsecondary'
                          : 'hover:ring-primary hover:ring-1 shadow border rounded bg-onprimary'
                      } flex justify-flex-start items-center   transition ease-in duration-200 
        text-center text-base rounded hover:ring-onsecondaryvariant hover:shadow-md`}
                    >
                      Longcontext_LLM-Cl
                    </button>
                    <button
                      type="button2"
                      onClick={() => {
                        setSelectedModel('internlm');
                        setUserInputShow(false);
                      }}
                      className={`py-2 px-3 mt-1 mb-2 ml-2 ${
                        selectedModel == 'internlm'
                          ? 'bg-onsecondaryvariant text-onsecondary'
                          : 'hover:ring-primary hover:ring-1 shadow border rounded bg-onprimary'
                      } flex justify-flex-start items-center   transition ease-in duration-200 
        text-center text-base rounded hover:ring-onsecondaryvariant hover:shadow-md`}
                    >
                      largeLLM-in
                    </button>
                    <button
                      type="button2"
                      onClick={() => {
                        setSelectedModel('qwen');
                        setUserInputShow(false);
                      }}
                      className={`py-2 px-3 mt-1 mb-2 ml-2 ${
                        selectedModel == 'qwen'
                          ? 'bg-onsecondaryvariant text-onsecondary'
                          : 'hover:ring-primary hover:ring-1 shadow border rounded bg-onprimary'
                      } flex justify-flex-start items-center   transition ease-in duration-200 
        text-center text-base rounded hover:ring-onsecondaryvariant hover:shadow-md`}
                    >
                      largeLLM-qw
                    </button>

                    <button
                      type="button2"
                      onClick={() => {
                        setSelectedModel('llama3_gradient');
                        setUserInputShow(false);
                      }}
                      className={`py-2 px-3 mt-1 mb-2 ml-2 ${
                        selectedModel == 'llama3_gradient'
                          ? 'bg-onsecondaryvariant text-onsecondary'
                          : 'hover:ring-primary hover:ring-1 shadow border rounded bg-onprimary'
                      } flex justify-flex-start items-center   transition ease-in duration-200 
        text-center text-base rounded hover:ring-onsecondaryvariant hover:shadow-md`}
                    >
                      llama3_gradient
                    </button>

                    <button
                      type="button2"
                      onClick={() => {
                        setSelectedModel('llama3_gradient_new');
                        setUserInputShow(false);
                      }}
                      className={`py-2 px-3 mt-1 mb-2 ml-2 mr-1 ${
                        selectedModel == 'llama3_gradient_new'
                          ? 'bg-onsecondaryvariant text-onsecondary'
                          : 'hover:ring-primary hover:ring-1 shadow border rounded bg-onprimary'
                      } flex justify-flex-start items-center   transition ease-in duration-200 
        text-center text-base rounded hover:ring-onsecondaryvariant hover:shadow-md`}
                    >
                      llama3_gradient_new
                    </button>
                  </>
                )}
              </div>
              <div className="w-full ">
                <div className="flex gap-2 items-center">
                  <h2 className="font-medium text-lg mb-1">
                    Select source data
                  </h2>
                  <span onClick={() => setToggleField(!toggleField)}>
                    {' '}
                    {!toggleField ? (
                      <div className=" cursor-pointer">
                        <IoIosArrowDown />
                      </div>
                    ) : (
                      <div className=" cursor-pointer">
                        <IoIosArrowUp />
                      </div>
                    )}
                  </span>
                </div>
                {toggleField && (
                  <Select
                    options={fieldName}
                    isMulti
                    value={selectedOptions}
                    onChange={handleSelectChange}
                  />
                )}
              </div>
            </div>

            <div className="w-full px-5">
              {userInputShow && (
                <input
                  type="text"
                  className=" py-2 w-full mt-2 
                rounded-lg border-[1px] mb-2 border-blue-500 px-2.5 text-[14px]"
                  name="prompt_name"
                  alt="Prompt_field"
                  value={apikeyValue}
                  placeholder="Enter Api key"
                  onChange={(e) => setApiKey(e.target.value)}
                ></input>
              )}
            </div>
            <div className="w-full px-5">
              {userInputShow && (
                <input
                  type="text"
                  className=" py-2 w-full mt-2 
                rounded-lg border-[1px] mb-2 border-blue-500 px-2.5 text-[14px]"
                  name="prompt_name"
                  alt="Prompt_field"
                  value={selectInstruction}
                  placeholder="System Prompt Instructions"
                  onChange={(e) => setSelectInstruction(e.target.value)}
                ></input>
              )}
            </div>
            {/* chat history */}
            <div className="w-full h-max px-5 flex flex-col gap-y-2 mb-1">
              <div className="flex flex-col justify-start gap-1  flex-wrap">
                <div className="flex gap-2 items-cente">
                  <div className="flex gap-2 items-center">
                    {' '}
                    <p className="font-medium  text-lg">Chat history</p>
                    <span onClick={() => setToggleHistory(!toggleHistory)}>
                      {' '}
                      {!toggleHistory ? (
                        <div className=" cursor-pointer">
                          <IoIosArrowDown />
                        </div>
                      ) : (
                        <div className=" cursor-pointer">
                          <IoIosArrowUp />
                        </div>
                      )}
                    </span>
                  </div>
                  <button
                    onClick={RefreshButton}
                    className="flex items-center cursor-pointer hover:bg-gray-200 p-2 rounded"
                  >
                    <span class="material-icons text-sm">refresh</span>
                  </button>
                </div>
                <div className="flex  gap-1  flex-wrap">
                  {toggleHistory && historyPillsValue.length > 0 && (
                    <div className="flex flex-wrap items-center">
                      <p className="text-lg mb-2 mr-4">Dates</p>
                      {toggleHistory &&
                        historyPillsValue.slice(0, 3).map((item) => {
                          return (
                            <button
                              className={`m-0.5 px-2 cursor-pointer hover:ring-primary hover:ring-1 shadow border rounded ${
                                selectedDate == item
                                  ? 'bg-primary text-onprimary'
                                  : 'bg-onprimary'
                              } `}
                              onClick={() => setSelectedDate(item)}
                            >
                              <div className="flex items-center py-1">
                                {selectedDate == item && (
                                  <span className="material-icons text-sm inline-block pr-1">
                                    check
                                  </span>
                                )}
                                <span className="text-sm border-l border-white inline-block pl-1 whitespace-nowrap">
                                  {item}
                                </span>
                              </div>
                            </button>
                          );
                        })}
                    </div>
                  )}
                </div>
                <div className="flex flex-wrap gap-1 ">
                  {toggleHistory && promptPillsArray.length > 0 && (
                    <div className="flex items-center">
                      <p className=" text-lg mb-2 mr-4">Prompts</p>
                      {toggleHistory &&
                        promptPillsArray.slice(0, 3).map((item) => {
                          return (
                            <button
                              className={`m-0.5 px-2 cursor-pointer hover:ring-primary hover:ring-1 shadow border rounded ${
                                selectedPrompt == item.id
                                  ? 'bg-primary text-onprimary'
                                  : 'bg-onprimary'
                              } `}
                              // onClick={() => historyPills(item)}
                              onClick={() => setSelectedPrompt(item.id)}
                              value="days"
                            >
                              <div className="flex items-center py-1">
                                {selectedDate == item.id && (
                                  <span className="material-icons text-sm inline-block pr-1">
                                    check
                                  </span>
                                )}
                                <span className="text-sm border-l border-white inline-block pl-1 whitespace-nowrap">
                                  {item.value.slice(0, 16)}...
                                </span>
                              </div>
                            </button>
                          );
                        })}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* Prompt */}
            <div className="w-full h-max px-5 flex  flex-col gap-y-2  mt-2">
              <div className="flex gap-2 items-center">
                {' '}
                <p className="font-medium text-lg">Prompts</p>
                <span onClick={() => setToggleIcon(!toggleIcon)}>
                  {' '}
                  {!toggleIcon ? (
                    <div className=" cursor-pointer">
                      <IoIosArrowDown />
                    </div>
                  ) : (
                    <div className=" cursor-pointer">
                      <IoIosArrowUp />
                    </div>
                  )}
                </span>
              </div>

              {toggleIcon && (
                <>
                  {' '}
                  <div className="flex justify-start gap-2 items-center w-full flex-wrap">
                    <Select
                      options={options}
                      onChange={handleChange}
                      value={options.find(
                        (option) => option.label === topPillsName,
                      )}
                      placeholder="select your prompt.."
                    />

                    <button
                      className="rounded-full p-1  flex justify-flex-start items-center ring-1 ring-primary text-primary transition ease-in duration-200 
        text-center text-base hover:bg-primary hover:text-onsecondary hover:shadow-md"
                      onClick={() => {
                        setIsClickedAddNew(!isClickedAddNew);
                        setUpdateDetails('');
                      }}
                    >
                      <svg
                        width={20}
                        height={20}
                        fill="none"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="w-full flex items-center justify-end px-1 h-[50px] my-2 border-t border-gray-50">
                    <div className="flex justify-center gap-2 items-center">
                      {isClickedAddNew && (
                        <input
                          type="text"
                          className="w-full py-2 ml-8 
                rounded-lg border-[1px] border-blue-500 px-2.5 text-[14px]"
                          name="prompt_name"
                          alt="Prompt_field"
                          value={newPromptName}
                          placeholder="Enter Prompt Name"
                          onChange={(e) => setNewPromptName(e.target.value)}
                        ></input>
                      )}
                      {isClickedAddNew && (
                        <button
                          onClick={handlePostRequest}
                          disabled={newPromptName == '' || newPromptValue == ''}
                          className={
                            'w-max h-max px-4 py-2 text-[16px] disabled:cursor-not-allowed bg-green-700 text-white rounded text-sm' +
                            (ChatgptData != null && !isPromptData
                              ? ' cursor-not-allowed opacity-60 '
                              : ' cursor-pointer opacity-100 hover:bg-green-600 ')
                          }
                        >
                          Save
                        </button>
                      )}
                      {updateDetails != '' && (
                        <button
                          onClick={handleEditRequest}
                          className={
                            'w-max h-max px-4 py-2 text-[16px] bg-green-700 text-white rounded text-sm' +
                            (ChatgptData != null && !isPromptData
                              ? ' cursor-not-allowed opacity-60 '
                              : ' cursor-pointer opacity-100 hover:bg-green-600 ')
                          }
                        >
                          Update
                        </button>
                      )}

                      <div className="flex justify-center items-center">
                        {updateDetails != '' && (
                          <button
                            onClick={handleDeleteRequest}
                            title="Delete prompt"
                            alt="delete prompt"
                            className="w-max h-max px-4 py-2 text-[16px] disabled:cursor-not-allowed whitespace-nowrap bg-green-700 text-white rounded text-sm cursor-pointer opacity-100 hover:bg-green-600 "
                          >
                            Delete
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className=" w-full px-5 pb-0 bottom-0 mt-1 ">
              {!isClickedAddNew && (
                <textarea
                  className="w-full  min-h-[140px] max-h-[250px] py-2 
                rounded-lg border-[1px] border-blue-500 px-2.5 text-[14px]"
                  name="prompt"
                  alt="Prompt_field"
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                ></textarea>
              )}
              <div className="">
                {isClickedAddNew && (
                  <textarea
                    className="w-full  min-h-[140px] max-h-[250px] py-2 
                rounded-lg border-[1px] border-blue-500 px-2.5 text-[14px]"
                    name="prompt"
                    alt="Prompt_field"
                    value={newPromptValue}
                    onChange={(e) => setNewPromptValue(e.target.value)}
                  ></textarea>
                )}
                <div className="flex flex-col sm:flex-row gap-4 items-center w-full ">
                  <button
                    title="search with voice"
                    alt="Default Prompt"
                    className="w-[30px] h-[30px] mx-2 my-4 rounded-full bg-gray-100 flex items-center justify-center hover:bg-gray-200"
                  >
                    <BsFillMicFill
                      onClick={() => settoggleMic(true)}
                      className="text-[14px]"
                    />
                  </button>
                  <div className="">
                    <select
                      className="form-select rounded text-sm"
                      onChange={(e) => {
                        setlang(e.target.value);
                      }}
                    >
                      <option className="text-sm" value={null}>
                        Select Your Language
                      </option>
                      {LANGUAGES_LIST.map((item) => (
                        <option
                          className="text-sm"
                          key={item.code}
                          value={item.language}
                        >
                          {item.language}
                        </option>
                      ))}
                    </select>
                  </div>
                  <button
                    onClick={() => {
                      handleFeedBtn();
                      setToggleIcon(false);
                    }}
                    disabled={
                      ChatgptData != null && !isPromptData ? true : false
                    }
                    className={
                      'w-max h-max px-4 py-2 text-[16px] bg-green-700 text-white rounded  text-sm' +
                      (ChatgptData != null && !isPromptData
                        ? ' cursor-not-allowed opacity-60 '
                        : ' cursor-pointer opacity-100 hover:bg-green-600 ')
                    }
                  >
                    Show Insights
                  </button>
                  {selectedModel == 'mx_groq' && (
                    <p>
                      {' '}
                      {timer == 30 || timer < 1
                        ? ''
                        : `Time remaining: ${timer} seconds, Please Wait...`}{' '}
                    </p>
                  )}
                </div>
                {summary.length > 0 && (
                  <div className="flex justify-center  sm:justify-end gap-4 mr-4  w-full px-5">
                    <button
                      alt="Default Prompt"
                      className="w-[30px] h-[30px] rounded-full bg-gray-100 flex items-center justify-center hover:bg-gray-200"
                    >
                      {isListing ? (
                        <PiSpeakerHighDuotone
                          className="text-[14px]"
                          onClick={() => {
                            // cancel();
                            window.speechSynthesis.cancel();
                            setisListing(false);
                          }}
                        />
                      ) : (
                        <PiSpeakerSimpleXBold
                          className="text-[14px]"
                          onClick={() => {
                            // text: line?.content,
                            const speech = new SpeechSynthesisUtterance(
                              summary?.[0]?.content,
                            );
                            window.speechSynthesis.speak(speech);

                            setisListing(true);
                          }}
                        />
                      )}
                    </button>
                    <button
                      title="Clear Search history"
                      alt="Default Prompt"
                      className="w-[30px] cursor-pointer h-[30px] rounded-full flex items-center bg-red-200 justify-center"
                      onClick={() => setSummary([])}
                    >
                      <AiFillDelete className="text-[16px] text-red-700" />
                    </button>

                    <button
                      title="Download report"
                      alt="Default Prompt"
                      className="w-[30px] cursor-pointer h-[30px] rounded-full flex items-center bg-green-200 justify-center"
                      onClick={convertHtmlToPdf}
                    >
                      <FaFileDownload className="text-[16px] text-green-700" />
                    </button>

                    <CopyTextButton text="Hello, world!" />
                  </div>
                )}
              </div>
            </div>

            <div
              className="px-6 pt-1 pb-6 overflow-y-auto text-[16px] w-full"
              ref={scrollContainerRef}
            >
              {isLoading && <LoaderBoxes message="Fetching" />}
              {summary.reverse().map((line, index) => {
                return (
                  <div key={index} className="border-b-2 my-2">
                    <Accordion
                      type="single"
                      collapsible
                      defaultValue="item-1"
                      className="mx-0 sm:mx-8 "
                    >
                      <AccordionItem value="item-1" defaultValue="item-1">
                        <AccordionTrigger className="font-bold text-lg  bg-white justify-start space-x-5">
                          <p className="font-bold text ">Past Conversations</p>
                        </AccordionTrigger>

                        <AccordionContent>
                          <div className="my-1 bg-gray-100 p-4">
                            <div className="flex justify-between">
                              <div className="font-bold flex gap-2 items-center">
                                <FaUserCircle size={25} />
                                <span>Question: </span>
                              </div>
                              {line?.token ? (
                                <span>Token Used : {line?.token} </span>
                              ) : null}
                            </div>
                            <div className="ml-8">{line?.user}</div>
                          </div>
                          <div className="mt-2 flex justify-between items-center p-4">
                            <div className="font-bold flex gap-2 items-center">
                              {/* <AiOutlineFileSearch size={25} /> */}

                              <div className="block">
                                <img
                                  src="/avatars/HAIXforwebsite.png"
                                  className=" h-auto w-10"
                                  alt="logo"
                                />
                              </div>
                              <span className="font-bold">
                                HaiX AI Secured Co-Pilot:{' '}
                              </span>
                            </div>
                          </div>

                          {line?.content?.split('\n').map((item, index) => (
                            <div key={index} className="ml-8 px-4">
                              {item.trim().match(/www/i) ? (
                                <>
                                  <p>{extractURLAndText(item.trim()).text}</p>
                                  <a
                                    className="!text-primary"
                                    href={extractURLAndText(item.trim()).url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {extractURLAndText(item.trim()).url}
                                  </a>
                                </>
                              ) : (
                                <p>{item}</p>
                              )}
                              <br />
                            </div>
                          ))}
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* </div> */}
        <Dialog
          showDialog={toggleMic}
          body={
            <MicListingComponent
              isMicON={isMicON}
              setisMicON={setisMicON}
              start={getuserMicData}
              stop={StopMic}
            />
          }
          footer={{
            text: 'close',
            callback: () => {
              settoggleMic(false);
              stop();
            },
          }}
        />
        <Dialog
          showDialog={modal}
          positiveButton1={{ text: 'Close', callback: () => setmodal(false) }}
          setShowDialog={setmodal}
          title="Disclaimer"
          body=" Please be aware that this complex social media analysis is conducted using AI and large language models, which may not always be highly accurate. It might contain personal data; therefore, adhere to GDPR policies when using the data. Use your discretion in decision-making."
        />
        {/* pdf elements */}
        <div className="hidden">
          <div id="pdf" className="px-6 pt-1 pb-6 overflow-y-scroll !text-sm">
            <div className="mt-3 flex justify-start px-2 items-center">
              <img
                src="/avatars/HAIXforwebsite.png"
                className="ml-2 h-auto w-24"
                alt="logo"
              />
            </div>{' '}
            <div className="text-sm flex justify-start items-center">
              Powered by HaiX AI
            </div>
            <div className="flex justify-end text-sm items-center px-2">
              Date: {dayjs(new Date().getTime()).format('DD/MMM/YYYY')}
            </div>
            <div className="text-[20px] text-green-700 border-b py-4 text-center font-semibold capitalize">
              {`"HaiX AI" Analysis for ${label}` || 'Welcome To HaiX AI'}
            </div>
            <div className="mt-4 mb-6 text-xs px-2">
              <span className="font-bold">HaiX AI Analysis Context</span>: This
              is short summary for {mainSearchTerm} on {socialMediaType} data
              for
              {ChatgptData?.title} data for {SearchObject?.days} days using{' '}
              {topPillsName}. For full customized report, reach our Customer
              Success team at <span className="">cs@haix.ai</span>.
            </div>
            {summary.reverse().map((line, index) => {
              return (
                <div key={index} className="border-b-2 my-2">
                  <div className="my-1 bg-green-100">
                    <div className="font-bold flex justify-between items-center">
                      <span>User Question: </span>
                    </div>
                    <div>{line?.user}</div>
                  </div>
                  <div className="mt-2">
                    <span className="font-bold">
                      Answer by HaiX AI Assistant:{' '}
                    </span>
                  </div>
                  {line?.content?.split('\n').map((item, index) => (
                    <div key={index}>
                      {item.trim().match(/www/i) ? (
                        <a
                          href={extractURLAndText(item).url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-primary"
                        >
                          {item}
                        </a>
                      ) : (
                        <p>{extractURLAndText(item).text}</p>
                      )}
                      <br />
                    </div>
                  ))}
                </div>
              );
            })}{' '}
            <div className="my-2 py-2 text-xs px-2">
              <span className="font-bold">Disclaimer</span>: Please be aware
              that this complex social media analysis is conducted using AI and
              large language models, which may not always be highly accurate. It
              might contain personal data; therefore, adhere to GDPR policies
              when using the data. Use your discretion in decision-making.
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
      {showModal && (
        <Dialog
          title={ModalTitle}
          body={showModalMessage}
          positiveButton1={{
            text: 'Ok',
            callback: () => {},
          }}
          showDialog={showModal}
          setShowDialog={setshowModal}
        />
      )}

      {showModalSubscribe && (
        <Dialog
          title={ModalTitle}
          body={showModalMessage}
          positiveButton1={{
            text: 'Subscribe',
            callback: () => {
              navigate('/');
            },
          }}
          showDialog={showModalSubscribe}
          setShowDialog={setShowModalSubscribe}
          negativeButton2={{
            text: 'Cancel',
            callback: () => {},
          }}
        />
      )}
    </div>
  );
};

export default memo(Chatgpt_API);

{
  /* <div className="w-[85%] h-max rounded bg-gray-200 p-3 text-[14px]">
<p className="text-[16px] w-full ">
  Secured & Private Generative AI Model
</p>
<p>
  Important Note: Please be aware that the data passing to the
  model might be truncated or cut short due to the input tokens
  limit. This could impact the model&apos;s performance and
  accuracy, so result&apos;s might not be as expected.
</p>
</div>

<div className="w-[85%] h-max rounded bg-gray-200 p-3 text-[14px]">
<p>
  Tip: Pro Tip: Enhance model performance by incorporating
  essential keywords and phrases such as &ldquo;provide a detailed
  summary of the following JSON data&ldquo; in your prompts. These
  cues can lead to more accurate and comprehensive results from
  the AI model.
</p>
</div> */
}
